// OneCompany
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import openNotification from "../../Components/Notifications";
import {
    Divider,
    Spin,
    Typography,
    Popconfirm,
    Result
} from "antd";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import {
    deleteCompany, getCompany
} from "../../API/fetch";
import COLORS from "../../Style/colors";
import CustomButton from "../../Components/CustomButton";
import { SettingOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const OneCompany = () => {
  const intl = useIntl();
  // get the params from the url;
  const { id } = useParams();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showGeneralInformation, setShowGeneralInformation] = useState(false);

  useEffect(() => {
    getCompanyInfo(id);
  }, [id, showGeneralInformation]);

  useEffect(() => {
    // scroll to top of page;
    window.scrollTo(0, 0);
  }, []);

  const getCompanyInfo = async (id) => {
    try {
      const res = await getCompany(id);
      if (!res?.data?.company) {
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
        setError(true);
        setLoading(false);
        return;
      }
      setCompany(res?.data?.company);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl,
      });
    }
  };

  const deleteCompanyInfo = async () => {
    try {
      setLoading(true);
      const res = await deleteCompany(company._id);
      if (res?.data?.status === "success") {
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        navigate("/companys");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      {error ? (
        <Result
          status={"404"}
          title={"404"}
          subTitle={"Sorry, the page you visited does not exist."}
          extra={
            <CustomButton
              className={`btn-text border-8 px-5 pb-5`}
              type='primary'
              onClick={() => navigate(-1)}
              text={intl.formatMessage({ id: "goBack" })}
            />
          }
        />
      ) : (
        <Spin spinning={loading}>
          <div
            style={
              rtl
                ? {
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <Title
              level={3}
              className='pointer'
              onClick={() => navigate(-1)}
              // style={{ position: "absolute", zIndex: 1 }}
            >
              {rtl ? (
                <IoArrowForwardOutline className='pointer' />
              ) : (
                <IoArrowBackOutline className='pointer' />
              )}
            </Title>
          </div>
          <br />

          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 10,
              width: "100%",
              minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <div style={{ display: "flex", alignItems: "center>" }}>
            
             
              <div className='mx-5 mt-5'>
                <Popconfirm
                  title={
                    <Text className='my-font'>
                      {intl.formatMessage({ id: "confirmDelete" })}
                    </Text>
                  }
                  okText={intl.formatMessage({ id: "delete" })}
                  okButtonProps={{
                    style: { color: COLORS.danger, fontFamily: "Cairo" },
                  }}
                  okType='text'
                  cancelText={intl.formatMessage({ id: "cancel" })}
                  cancelButtonProps={{
                    style: { border: "none", fontFamily: "Cairo" },
                  }}
                  onConfirm={deleteCompanyInfo}
                  disabled={company?.didPaid || loading}
                  loading={loading}
                >
                  <Text
                    type='danger'
                    className='pointer my-font'
                    disabled={company?.didPaid || loading}
                  >
                    {intl.formatMessage({ id: "delete" })}
                  </Text>
                </Popconfirm>
              </div>
            </div>
            <Divider />
            <Title level={3}>
              {intl.formatMessage({ id: "subscription" })}
            </Title>

            <Divider />
            <Title level={3}>{intl.formatMessage({ id: "edit" })}</Title>

            <Divider />
            <Title level={3}>{intl.formatMessage({ id: "addBranch" })}</Title>

            <Divider />
            <Title level={3}>{intl.formatMessage({ id: "application" })}</Title>

            <Divider />
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              // minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <Title level={3}>{intl.formatMessage({ id: "integration" })}</Title>

            <CustomButton
              className={`btn-text border-8 px-5 pb-5 mt-4`}
              type='primary'
              text={intl.formatMessage({ id: "manage" })}
              onClick={() => navigate(`/integrations/${company?._id}`)}
              icon={<SettingOutlined />}
            />
          </div>
          <div
            style={{
              backgroundColor: COLORS.white,
              marginTop: 20,
              width: "100%",
              // minHeight: "70vh",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: 10,
              padding: 20,
            }}
            className='light-shadow'
          >
            <Title level={3}>{intl.formatMessage({ id: "payments" })}</Title>
          </div>
        </Spin>
      )}
    </div>
  );
};

export default OneCompany;
