import React, { useEffect, useState } from "react";
import { Row, Space, Spin, Tag, Typography } from "antd";
import "chart.js/auto";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import { IoAddCircleOutline, IoRefreshOutline } from "react-icons/io5";
import { Table } from "ant-table-extensions";
import {
  EditOutlined,
  FileExcelOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { getAllDrivers, addDriver, sendOrderTest } from "../../API/fetch";
import { setDriversAction } from "../../redux/reducers/companiesReducer";
import AddDriverModal from "./AddDriverModal";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";
import * as XLSX from "xlsx";
import { ORDER_DRIVER_STATUS } from "../../Types";

const { Text, Title } = Typography;

const Drivers = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { drivers, company } = useSelector((state) => state.companies);

  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(true);
  const [formattedDrivers, setFormattedDrivers] = useState([]);
  const [showAddDriverModal, setShowAddDriverModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  useEffect(() => {
    if (drivers?.length === 0 && loading) {
      getDrivers();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (drivers?.length) {
      const formatted = drivers.map((driver) => {
        let sentOrders = 0;
        let deliveredOrders = 0;
        let canceledOrders = 0;
        driver?.orders?.forEach((order) => {
          sentOrders++
          if(order?.status === ORDER_DRIVER_STATUS.REJECTED || order?.status === ORDER_DRIVER_STATUS.CANCELED || order?.status === ORDER_DRIVER_STATUS.PENDING){
            canceledOrders++
          }else {
            deliveredOrders++
          }
        });
        return {
          key: driver?._id,
          name: driver?.name,
          sentOrders,
          deliveredOrders,
          canceledOrders,
          phone: driver?.phone,
          email: driver?.email,
          status: driver?.status,
          createdAt: driver?.createdAt,
          driverObject: driver,
        };
      });
      setFormattedDrivers(formatted);
    }
  }, [drivers]);

  const getDrivers = async () => {
    try {
      setLoading(true);
      const res = await getAllDrivers();
      if (res?.data?.drivers) {
        dispatch(setDriversAction(res?.data?.drivers));
        openNotification({
          title: "success",
          description: "drivers fetched successfully",
          type: "success",
          rtl: true,
        });
      } else {
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const downloadTemplate = async () => {
    try {
      setLoading(true);
      // create xlsx workbook with one sheet that has headers only
      // the headers are : name, phone,  password,email;
      // and download it;
      const headers = ["name", "phone", "password", "email"];
      const data = [headers];
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      XLSX.writeFile(wb, "drivers_template.xlsx");

      setLoading(false);
    } catch (e) {
      console.log(e);
      console.log(e?.response?.data);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      // alert(e?.response?.data?.error);
    }
  };

  const uploadTemplate = () => {
    // get the input file and click it
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const onFileChange = async (e) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const rows = XLSX.utils.sheet_to_json(worksheet, { raw: false });
          if (rows?.length === 0) {
            openNotification({
              title: "error",
              description: "the file is empty",
              type: "error",
              rtl,
            });
            setLoading(false);
            return;
          }
          const cleanData = getCleanData(rows);
          // check if the rows are valid

          const res = await addDriver(cleanData, "Multiple");
          openNotification({
            title: "success",
            description: "drivers added successfully",
            type: "success",
            rtl,
          });
          if (res?.data?.error?.length) {
            openNotification({
              title: intl.formatMessage({ id: "phoneDublcated" }),
              description: res?.data?.error?.join(", \n"),
              type: "error",
              rtl,
            });
          }
          setLoading(false);
          getDrivers();
        } catch (error) {
          console.log(error?.response?.data);
          if (
            error?.response?.data?.error &&
            error?.response?.data?.error?.code === 11000
          ) {
            openNotification({
              title: intl.formatMessage({ id: "phoneDublcated" }),
              description: error?.response?.data?.error?.phone,
              type: "error",
              rtl,
            });
            setLoading(false);
          } else {
            setLoading(false);
            openNotification({
              title: "error",
              description: "something went wrong",
              type: "error",
              rtl,
            });
          }
        }
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      console.log(error?.response?.data);
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl,
      });
    }
  };

  const getCleanData = (rows) => {
    const cleanData = [];
    rows.forEach((row) => {
      const cleanRow = {};
      if (row?.name && row?.name?.length >= 3) {
        cleanRow.name = row?.name;
      } else {
        return;
      }
      if (row?.phone && getFormattedPhone(row?.phone)) {
        cleanRow.phone = getFormattedPhone(row?.phone);
      } else {
        return;
      }
      if (row?.password && row?.password?.length >= 6) {
        cleanRow.password = row?.password;
      } else {
        return;
      }
      if (row?.email) {
        cleanRow.email = row?.email;
      }
      cleanRow.status = "Active";
      cleanRow.company = company?._id;
      cleanData.push(cleanRow);
    });
    return { drivers: cleanData };
  };

  const getFormattedPhone = (phone) => {
    // check the length of the phone;
    let str = `${phone}`;
    if (str?.length === 10 && str?.startsWith("05")) {
      return `966${str.substring(1)}`;
    } else if (str?.length === 9 && str?.startsWith("5")) {
      return `966${str}`;
    } else if (str?.length === 12 && str?.startsWith("9665")) {
      return str;
    } else if (str?.length === 14 && str?.startsWith("009665")) {
      return str.substring(2);
    } else if (str?.length === 13 && str?.startsWith("+9665")) {
      return str.substring(1);
    } else {
      return null;
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: 50,
      render: (text, r, index) => <Text className='my-font'>{index + 1}</Text>,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: intl.formatMessage({ id: "deliveredOrders" }),
      dataIndex: "deliveredOrders",
      key: "deliveredOrders",
    },
    {
      title: intl.formatMessage({ id: "canceledOrders" }),
      dataIndex: "canceledOrders",
      key: "canceledOrders",
    },
    {
      title: intl.formatMessage({ id: "sentOrders" }),
      dataIndex: "sentOrders",
      key: "sentOrders",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (text, r) => {
        return (
          <Tag color={r?.status === "Active" ? "success" : "red"}>
            {intl.formatMessage({ id: r?.status || "N/A" })}
          </Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      key: "edit",
      render: (text, r) => {
        return (
          <CustomButton
            text={intl.formatMessage({ id: "edit" })}
            type='primary'
            className={"border-8"}
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedDriver(r.driverObject);
              setShowAddDriverModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <AddDriverModal
          show={showAddDriverModal}
          hide={() => {
            setShowAddDriverModal(false);
          }}
          driver={selectedDriver}
        />
        <Space direction='vertical' size='large'>
          <input
            value={lat}
            onChange={(e) => {
              setLat(e.target.value);
            }}
          />
          <input
            value={lng}
            onChange={(e) => {
              setLng(e.target.value);
            }}
          />
        </Space>
        <CustomButton
          text='TEST_ORDER'
          type='primary'
          onClick={async () => {
            try {
              const res = await sendOrderTest(lat, lng);
            } catch (e) {
              console.log(e);
              console.log(e?.response?.data);
              alert(e?.response?.data?.error);
            }
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "drivers" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getDrivers}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <CustomButton
            text={intl.formatMessage({ id: "addDriver" })}
            type='primary'
            icon={
              <IoAddCircleOutline
                style={{
                  fontSize: 16,
                  position: "relative",
                  top: 5,
                  marginRight: 5,
                  marginLeft: 5,
                }}
              />
            }
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
            className={"border-8 px-3"}
            onClick={() => {
              setSelectedDriver(null);
              setShowAddDriverModal(true);
            }}
          />
          <Space direction='horizontal' size={10}>
            <CustomButton
              text={intl.formatMessage({ id: "downloadTemplate" })}
              type='outline'
              icon={
                <AiOutlineCloudDownload
                  style={{
                    fontSize: 16,
                    position: "relative",
                    top: 5,
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                />
              }
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
              className={"border-8 px-3"}
              onClick={downloadTemplate}
            />
            <input
              type='file'
              style={{
                display: "none",
              }}
              id='fileInput'
              onChange={onFileChange}
              // only allow excel files
              accept='.xlsx, .xls, .csv'
            />
            <CustomButton
              text={intl.formatMessage({ id: "uploadTemplate" })}
              type='primary'
              icon={
                <FiUpload
                  style={{
                    fontSize: 16,
                    position: "relative",
                    top: 5,
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                />
              }
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
              className={"border-8 px-3"}
              onClick={uploadTemplate}
            />
          </Space>
        </div>

        <Table
          searchable
          fuzzySearch
          //   exportable

          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table",
              prefix: <SearchOutlined />,
              disabled: loading || formattedDrivers?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
            pageSizeOptions: [10, 25, 50, 100, 200, 500],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedDrivers}
          scroll={{ x: 800 }}
          // footer={(currentPageData) => {
          //   DATA.current = currentPageData;
          //   return null;
          // }}
        />
      </Spin>
    </div>
  );
};

export default Drivers;
