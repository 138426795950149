import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import EnterCode from "../Views/Auth/Components/EnterCode";

const PrivateRoute = ({ children }) => {
  const search = useLocation();

  const { isLoggedIn, userHasLevel } = useSelector((state) => state.auth);

 

  if (isLoggedIn === true) {
     if (userHasLevel === true) {
      return children;
    } else {
      return <EnterCode />;
    }
  } else {
    return <Navigate to='/register' />;
  }
};

export default PrivateRoute;
