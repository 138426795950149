import React, { useState, useEffect } from "react";
import { Row, Spin, Tag, Typography } from "antd";
import "chart.js/auto";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import openNotification from "../../Components/Notifications";
import { setCompaniesAction } from "../../redux/reducers/companiesReducer";
import { getAllCompanies } from "../../API/fetch";
import CustomButton from "../../Components/CustomButton";
import { IoAddCircleOutline, IoRefreshOutline } from "react-icons/io5";
import AddCompanyModal from "./AddCompanyModal";
import { Table } from "ant-table-extensions";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import COLORS from "../../Style/colors";
import moment from "moment";
// import AddApplicationModal from "./AddApplicationModal";
const { Text, Title, Link } = Typography;

const Admin = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const { companies } = useSelector((state) => state.companies);
  const [loading, setLoading] = useState(true);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [addApplicationModal, setAddApplicationModal] = useState(false)
  const [formattedCompanies, setFormattedCompanies] = useState([]);
  useEffect(() => {
    if (user?.role === "admin") {
      // get admin data;
      if (companies?.length === 0) {
        getCompaniesData();
      } else {
        setLoading(false);
      }
    } else {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (companies?.length) {
      let formatted = companies.map((company) => {
        return {
          key: company?._id,
          name: company?.name,
          phone: company?.phone,
          email: company?.email,
          city: company?.city,
          deliveryFees: company?.deliveryFees,
          drivers: company?.drivers,
          orders: company?.orders,
          createdAt: company?.createdAt,
          mangers: company?.mangers,
        };
      });
      setFormattedCompanies(formatted);
    }
  }, [companies]);

  const getCompaniesData = async () => {
    try {
      const res = await getAllCompanies();
      console.log("res", res.status, res.data);
      if (res?.status === 200) {
        setLoading(false);
        dispatch(setCompaniesAction(res?.data?.companies));
        openNotification({
          type: "success",
          title: intl.formatMessage({ id: "success" }),
          description: "companies data fetched successfully",
          rtl,
        });
        return;
      }
      setLoading(false);
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: "something went wrong",
        rtl,
      });
    } catch (error) {
      openNotification({
        type: "error",
        title: intl.formatMessage({ id: "error" }),
        description: "something went wrong",
        rtl,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      render: (text, record, index) => (index + 1).toString(),
      width: 50,
    },
    {
      title: intl.formatMessage({ id: "companyName" }),
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return <Link href={`/admin/company/${record?.key}`}>{text}</Link>;
      },
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    // {
    //   title: intl.formatMessage({ id: "email" }),
    //   dataIndex: "email",
    //   key: "email",
    // },
    {
      title: intl.formatMessage({ id: "cityName" }),
      dataIndex: "city",
      key: "city",
    },
    {
      title: intl.formatMessage({ id: "deliveryFees" }),
      dataIndex: "deliveryFees",
      key: "deliveryFees",
    },
    {
      title: intl.formatMessage({ id: "drivers" }),
      dataIndex: "drivers",
      key: "drivers",
    },
    {
      title: intl.formatMessage({ id: "orders" }),
      dataIndex: "orders",
      key: "orders",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => {
        return moment(text).format("YYYY-MM-DD");
      },
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "mangers",
      key: "mangers",
      render: (mangers, record, index) => {
        return <Tag color='green'>{mangers?.map((m) => m?.name + " - ")}</Tag>;
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <AddCompanyModal
          show={showAddCompanyModal}
          hide={() => {
            setShowAddCompanyModal(false);
          }}
        />
        {/* <AddApplicationModal
          show={addApplicationModal}
          hide={() => {
            setAddApplicationModal(false);
          }}
        /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "companies" })}
          </Title>
          <CustomButton
            text={intl.formatMessage({ id: "refresh" })}
            type='text'
            icon={<IoRefreshOutline />}
            onClick={getCompaniesData}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <CustomButton
            text={intl.formatMessage({ id: "addCompany" })}
            type='primary'
            icon={
              <IoAddCircleOutline
                style={{
                  fontSize: 16,
                  position: "relative",
                  top: 5,
                  marginRight: 5,
                  marginLeft: 5,
                }}
              />
            }
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
            className={"border-8 px-3"}
            onClick={() => {
              setShowAddCompanyModal(true);
            }}
          />
          {/* <CustomButton
            text={intl.formatMessage({ id: "addApplication" })}
            type='primary'
            icon={
              <IoAddCircleOutline
                style={{
                  fontSize: 16,
                  position: "relative",
                  top: 5,
                  marginRight: 5,
                  marginLeft: 5,
                }}
              />
            }
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
            className={"border-8 px-3"}
            onClick={() => {
              setAddApplicationModal(true);
            }}
          /> */}
        </div>

        <Table
          searchable
          fuzzySearch
          exportable
          exportableProps={{
            disabled: loading || formattedCompanies?.length === 0,
            btnProps: {
              icon: <FileExcelOutlined />,
              type: "primary",
              style: { borderRadius: 7, position: "relative", zIndex: -20 },
              id: "exportBTN",
            },
            children: (
              <Text
                className='my-font'
                style={{
                  color:
                    formattedCompanies?.length === 0 ? "#dddddd" : COLORS.white,
                }}
              >
                {intl.formatMessage({ id: "export" })}
              </Text>
            ),
          }}
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table",
              prefix: <SearchOutlined />,
              disabled: loading || formattedCompanies?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
            pageSizeOptions: [10, 25, 50, 100, 200, 500],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedCompanies}
          scroll={{ x: 1500 }}
          // footer={(currentPageData) => {
          //   DATA.current = currentPageData;
          //   return null;
          // }}
        />
      </Spin>
    </div>
  );
};

export default Admin;
