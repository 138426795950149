import { createSlice } from "@reduxjs/toolkit";
import { arrAdd, arrUpdateByKeyValue } from "../../../helpers/array";

const initialState = {
  payments: [],
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setPayments: (state, action) => {
      state.payments = action.payload;
    },
  },
});

export const { setPayments: setPaymentsAction } = adminSlice.actions;

export default adminSlice.reducer;
