import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Typography, Spin, Form, DatePicker, Space } from "antd";
import { useIntl } from "react-intl";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../../Components/CustomButton";
import moment from "moment";
import { getPayments } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import { setPaymentsAction } from "../../../redux/reducers/adminReducer";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import EditPaymentModal from "./Components/EditPaymentModal";

const { Text, Title } = Typography;
const Payments = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { payments } = useSelector((state) => state.admin);
  const { rtl } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showTotal, setShowTotal] = useState(false);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [totalTillNow, setTotalTillNow] = useState(0);

  const getPaymentsData = async (values) => {
    try {
      setLoading(true);
      let dateStart;
      let dateEnd;
      // check if values.data is === Sunday;
      if (values.date.day() === 4) {
        // if Thursday then make start date is pervious Thursday && end date is pervious Wednesday;
        dateStart = moment(values.date)
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        dateEnd = moment(values.date).subtract(1, "days").format("YYYY-MM-DD");
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
        return;
      }
      const query = `?dateStart=${dateStart}&dateEnd=${dateEnd}`;
      const res = await getPayments(query);
      console.log(res.data);
      if (res?.data?.status === "success") {
        dispatch(setPaymentsAction(res.data.companies));
        setLoading(false);
        openNotification({
          title: "success",
          description: "payments loaded",
          type: "success",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: "error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record) => {
        return <Link to={`/restaurants/${record.restaurantId}`}>{number}</Link>;
      },
      fixed: "left",
      index: 0,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return (
          <Space direction='vertical'>
            <Text>{rtl ? name : record?.name_en}</Text>
            <Text type='secondary'>{record?.bankAccountHolderName}</Text>
          </Space>
        );
      },
      fixed: "left",
      index: 1,
    },

    {
      title: intl.formatMessage({ id: "countOfOrders1" }),
      dataIndex: "countOfOrders",
      key: "countOfOrders",
      render: (countOfOrders) => {
        return <Text>{countOfOrders}</Text>;
      },
    },

    {
      title: intl.formatMessage({ id: "VATFees" }),
      dataIndex: "VATFees",
      key: "VATFees",
      render: (VATFees) => {
        return <Text>{VATFees?.toFixed(2)}</Text>;
      },
    },

    {
      title: intl.formatMessage({ id: "amountToTransfer" }),
      dataIndex: "amountToTransfer",
      key: "amountToTransfer",
      render: (totalToTransfer, record) => {
        return <Text copyable>{totalToTransfer?.toFixed(2)}</Text>;
      },
    },

    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Tag
            color={status === "Success" ? "success" : "red"}
            className='my-font'
          >
            {status}
          </Tag>
        );
      },
      onFilter: (value, record) => {
        if (value === "Success") {
          return record.status === "Success";
        } else {
          return record.status !== "Success";
        }
      },
      filters: [
        {
          text: "Success",
          value: "Success",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
    },

    {
      title: intl.formatMessage({ id: "edit" }),
      dataIndex: "edit",
      key: "edit",
      render: (actions, record) => {
        return (
          <CustomButton
            onClick={() => {
              setSelectedPayment(record?.paymentObject);
              setShowModal(true);
            }}
            type='primary'
            className='border-8'
            icon={<EditOutlined />}
            text={intl.formatMessage({ id: "edit" })}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (payments?.length) {
      const formatted = payments.map((payment, i) => {
        return {
          key: payment?._id,
          company: payment?._id,
          countOfOrders: payment?.orderCount,
          name: payment?.name,
          VATFees: 0,
          amountToTransfer: payment?.amountToTransfer,
          status: payment?.status,
          bankAccountHolderName: payment?.bankAccountHolderName,
          paymentObject: {
            ...payment,
            company: payment?._id,
          },
        };
      });
      let sorted = formatted
        .sort((a, b) => {
          return b.amountToTransfer - a.amountToTransfer;
        })
        .map((item, i) => {
          return {
            ...item,
            number: i + 1,
          };
        });
      setFormattedPayments(sorted);
    } else {
      setFormattedPayments([]);
    }
    setLoading(false);
  }, [rtl, payments]);

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <EditPaymentModal
          show={showModal}
          hide={() => {
            setShowModal(false);
            setSelectedPayment(null);
          }}
          payment={selectedPayment}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "payments" })}
          </Title>
          <Spin spinning={loadingTotal} />
          <Form
            name='payment-data'
            form={form}
            preserve={false}
            className='form-container'
            layout='inline'
            onFinish={getPaymentsData}
          >
            <Form.Item
              name='date'
              label={intl.formatMessage({ id: "transferTime" })}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                disabledDate={(currentDate) => {
                  // if the day is not Sunday or Thursday, disabled it;
                  if (currentDate.day() !== 4) {
                    return true;
                  }
                  return false;
                }}
              />
            </Form.Item>
            <Form.Item>
              <CustomButton
                htmlType='submit'
                text={intl.formatMessage({ id: "send" })}
                className={`btnRegister btn-text border-8`}
                loading={loading}
                disabled={loading}
                type='primary'
              />
            </Form.Item>
          </Form>
        </div>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formattedPayments?.length === 0,
            },
          }}
          pagination={{
            position: ["topLeft"],
          }}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formattedPayments}
          scroll={{ x: 600 }}
        />
      </Spin>
    </div>
  );
};

export default Payments;
