import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CustomButton from "../../Components/CustomButton";
import { IoRefreshOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import openNotification from "../../Components/Notifications";
import COLORS from "../../Style/colors";
import { checkIfValidPhone } from "../../helpers";
import phoneCodes from "../Auth/PhoneCods.json";
import { BANKS } from "../../Types";
import apiUrl from "../../API/config";
import { UploadOutlined } from "@ant-design/icons";
import citiesKSA from "../../Components/cities.json";
import { setCompanyAction } from "../../redux/reducers/companiesReducer";
import { createToken, editCompany } from "../../API/fetch";
const { Text, Title } = Typography;
const acceptedTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];
const { Option } = Select;
const prefixSelector = (
  <Form.Item name='prefix' noStyle>
    <Select
      style={{
        width: 150,
      }}
      disabled={true}
    >
      {phoneCodes.map((info) => {
        return (
          <Option key={`${info.dial_code}-${info.name}`} value={info.dial_code}>
            {info.dial_code} {info.name}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);
const Settings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rtl } = useSelector((state) => state.settings);
  const { company } = useSelector((state) => state.companies);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [CommercialRegister, setCommercialRegister] = useState([]);
  const [CommercialLicense, setCommercialLicense] = useState([]);
  const [ArticlesOfAssociation, setArticlesOfAssociation] = useState([]);
  const [ids, setIds] = useState([]);
  const [BankAccountLetter, setBankAccountLetter] = useState([]);
  const [sortedCities, setSortedCities] = useState(citiesKSA);
  useEffect(() => {
    if (citiesKSA.length > 0) {
      let copy = [...citiesKSA];
      setSortedCities(copy.sort((a, b) => a.name_ar.localeCompare(b.name_ar)));
    }
  }, [citiesKSA]);

  useEffect(() => {
    if (company?.commercialLicense) {
      setCommercialLicense([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: company?.commercialLicense,
        },
      ]);
    }
    if (company?.commercialRegister) {
      setCommercialRegister([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: company?.commercialRegister,
        },
      ]);
    }
    if (company?.articlesOfAssociation) {
      setArticlesOfAssociation([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: company?.articlesOfAssociation,
        },
      ]);
    }
    if (company?.ids) {
      setIds([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: company?.ids,
        },
      ]);
    }
    if (company?.bankAccountLetter) {
      setBankAccountLetter([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: company?.bankAccountLetter,
        },
      ]);
    }
  }, [company]);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = {
        company: {
          ...values,
        },
      };
      delete data.company.prefix;
      delete data.company.phone;
      if(company?.email){
        delete data.company.email;
      }
      delete data.company.integrationToken;
      let status = values?.status ? "Active" : "Disabled";
      data.company.status = status;

      const res = await editCompany(data);
      if (res?.data?.company) {
        const newComp = {
          ...company,
          ...res?.data?.company,
        };
        dispatch(setCompanyAction(newComp));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
      } else {
        setLoading(false); // hide loading;
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const createNewToken = async () => {
    try {
      setLoading(true);
      const res = await createToken();
      if (res?.data?.token) {
        const newComp = {
          ...company,
          integrationToken: res?.data?.token,
        };
        dispatch(setCompanyAction(newComp));
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      } else {
        setLoading(false); // hide loading;
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }

    return true;
  };
  const handleChangeImages = ({ fileList, file }, type) => {
    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      // upload success;
      setLoading(false); // hide loading;
      if (file.response) {
        if (file?.response) {
          openNotification({
            title: intl.formatMessage({ id: "savedSuccessfully" }),
            type: "success",
            rtl,
          });
        }
      }
    } else if (file.status === "error") {
      setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    if (type === "CommercialRegister") {
      setCommercialRegister([file]);
    } else if (type === "CommercialLicense") {
      setCommercialLicense([file]);
    } else if (type === "ArticlesOfAssociation") {
      setArticlesOfAssociation([file]);
    } else if (type === "BankAccountLetter") {
      setBankAccountLetter([file]);
    } else if (type === "ids") {
      setIds([file]);
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Modal
        visible={previewVisible}
        title={rtl ? company?.name : company?.name_en}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt={company?.name} style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title
          level={4}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {intl.formatMessage({ id: "settings" })}
        </Title>
      </div>
      <Spin spinning={loading}>
        <Form
          name='settings-form'
          form={form}
          className='form-container'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            name: company?.name,
            nameAr: company?.nameAr,
            phone: company?.phone?.substring(3),
            prefix: "+966",
            email: company?.email,
            status: company?.status === "Active" ? true : false,
            bankAccount: company?.bankAccount,
            bankAccountHolderName: company?.bankAccountHolderName,
            bankId: company?.bankId,
            iban: company?.iban,
            city: company?.city,
            deliveryFees: company?.deliveryFees,
            // integrationToken: company?.integrationToken
            //   ? "***************************"
            //   : "",
            vatNumber: company?.vatNumber,
            // webHook: company?.webHook,
          }}
        >
          {() => (
            <div style={{ backgroundColor: COLORS.gray }}>
              <div
                style={{
                  backgroundColor: COLORS.white,
                  padding: "10px 20px 20px",
                  borderRadius: 7,
                }}
                className='light-shadow'
              >
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Names */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "nameEn" })}
                      className='mb-1'
                      name='name'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "nameAr" })}
                      className='mb-1'
                      name='nameAr'
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorname" }),
                        },
                      ]}
                    >
                      <Input className={"input"} style={{ borderRadius: 5 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className='mt-4'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Vat and email */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "vatNumber" })}
                      className='mb-1'
                      name='vatNumber'
                      style={{ borderRadius: 20 }}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "email" })}
                      className='mb-1'
                      name='email'
                      rules={[
                        {
                          required: true,
                        },
                        {
                          // validate email
                          type: "email",
                          message: "Check you email",
                        },
                      ]}
                    >
                      <Input
                        disabled={company?.email ? true: false}
                        className={"input"}
                        style={{ borderRadius: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className='mt-4'
                  gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                >
                  {/* Phone*/}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='phone'
                      label={intl.formatMessage({ id: "phone" })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: "errorphone" }),
                        },
                        {
                          validator: (_, value) => {
                            const prefix = form.getFieldValue("prefix");
                            if (!prefix) {
                              return Promise.reject(
                                intl.formatMessage({ id: "errorphone" })
                              );
                            }
                            let valid = checkIfValidPhone(`${prefix}${value}`);
                            if (valid) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                intl.formatMessage({ id: "errorphone" })
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        addonBefore={rtl ? null : prefixSelector}
                        addonAfter={rtl ? prefixSelector : null}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    {/* deliveryFees */}
                    <Form.Item
                      label={intl.formatMessage({ id: "deliveryFees" })}
                      className='mb-1'
                      name='deliveryFees'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                        },
                        {
                          // validate input is number;
                          pattern: /^[0-9]*$/,
                          message: "Please enter a valid number",
                        },
                      ]}
                    >
                      <Input
                        className={"input"}
                        style={{ borderRadius: 5, width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Date and payment */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "cityName" })}
                      className='mb-1'
                      name='city'
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.value ?? "").includes(input)
                        }
                        className='my-font'
                      >
                        {sortedCities.map((info) => {
                          return (
                            <Option
                              key={info?.city_id}
                              value={`${info?.name_en} - ${info?.name_ar}`}
                              className='my-font'
                            >
                              {info.name_en} - {info.name_ar}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "status" })}
                      className='mb-1'
                      name='status'
                      style={{ borderRadius: 20 }}
                      valuePropName='checked'
                    >
                      <Switch
                        checkedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "active",
                            })}
                          </p>
                        }
                        unCheckedChildren={
                          <p>
                            {intl.formatMessage({
                              id: "disabled",
                            })}
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Date and payment */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "bankAccountName" })}
                      tooltip={
                        <Text className='my-font'>
                          {intl.formatMessage({ id: "bankAccountNameTool" })}
                        </Text>
                      }
                      className='mb-1'
                      name='bankAccountHolderName'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "bankAccount" })}
                      className='mb-1'
                      name='bankAccount'
                      style={{ borderRadius: 20 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  {/* Date and payment */}
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "iban" })}
                      className='mb-1'
                      name='iban'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                        },
                        {
                          // check if the first two letters are SA
                          pattern: /^SA/,
                          message: 'IBAN must start with "SA"',
                        },
                        {
                          // check if the length is 24
                          pattern: /^.{24}$/,
                          message: "IBAN must be 24 characters long",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      name='bankId'
                      label={intl.formatMessage({ id: "chooseBank" })}
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select className='my-font'>
                        {BANKS?.map((bank) => (
                          <Select.Option
                            className='my-font'
                            key={bank.value}
                            value={`${bank.value}`}
                          >
                            {rtl ? bank.text : bank?.text_en}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={intl.formatMessage({ id: "webHook" })}
                      className='mb-1'
                      name='webHook'
                      style={{ borderRadius: 20 }}
                      rules={[
                        {
                          // validate it is url with https;
                          pattern: /^https:\/\//,
                          message: "Please enter a valid url",
                        },
                        // validate it is a vlaid url;
                        {
                          type: "url",
                          message: "Please enter a valid url",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Title level={5}>
                      {intl.formatMessage({ id: "integrationToken" })}
                    </Title>
                    <Space direction='horizontal' size='large'>
                      {company?.integrationToken ? (
                        <Text
                          copyable={{
                            text: company?.integrationToken,
                          }}
                          className='my-font'
                          type={"secondary"}
                        >
                          ***************************
                        </Text>
                      ) : (
                        <Text className='my-font' type={"primary"}>
                          {intl.formatMessage({ id: "noIntegrationToken" })}
                        </Text>
                      )}
                      <CustomButton
                        text={intl.formatMessage({
                          id: "generateNewToken",
                        })}
                        type='primary'
                        className='border-8'
                        onClick={createNewToken}
                      />
                    </Space>
                  </Col>
                </Row> */}

                <Row className='mt-4'>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      disabled={loading}
                      type='primary'
                      text={intl.formatMessage({ id: "save" })}
                      style={{ minWidth: 100 }}
                    />
                  </Form.Item>
                </Row>
              </div>
            </div>
          )}
        </Form>
        <div
          style={{
            backgroundColor: COLORS.white,
            padding: "10px 20px 20px",
            borderRadius: 7,
            marginTop: 20,
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          }}
        >
          <Title level={5}>{intl.formatMessage({ id: "files" })}</Title>

          <Row className='mt-2' gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}>
            <Col md={12} sm={24} xs={24}>
              <Text className='my-font' type={"primary"}>
                {intl.formatMessage({ id: "CommercialRegister" })}
              </Text>
              <br />
              <Upload
                action={`${apiUrl}/company/document?name=commercialRegister`}
                headers={{ authorization: "Bearer " + user.token }}
                name='file'
                fileList={CommercialRegister}
                listType='file'
                onChange={(info) =>
                  handleChangeImages(info, "CommercialRegister")
                }
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                multiple={false}
                maxCount={0}
                accept='image/jpg,image/jpeg,image/png,application/pdf'
                disabled={loading}
              >
                <Button
                  type='primary'
                  className='border-8 mt-2'
                  icon={<UploadOutlined />}
                  disabled={loading}
                >
                  {intl.formatMessage({ id: "clickToUpload" })}
                </Button>
              </Upload>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Text className='my-font' type={"primary"}>
                {intl.formatMessage({ id: "CommercialLicense" })}
              </Text>
              <br />
              <Upload
                action={`${apiUrl}/company/document?name=commercialLicense`}
                headers={{ authorization: "Bearer " + user.token }}
                name='file'
                fileList={CommercialLicense}
                listType='file'
                onChange={(info) =>
                  handleChangeImages(info, "CommercialLicense")
                }
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                multiple={false}
                maxCount={0}
                accept='image/jpg,image/jpeg,image/png,application/pdf'
                disabled={loading}
              >
                <Button
                  type='primary'
                  className='border-8 mt-2'
                  disabled={loading}
                  icon={<UploadOutlined />}
                >
                  {intl.formatMessage({ id: "clickToUpload" })}
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }} className='mt-5'>
            <Col md={12} sm={24} xs={24}>
              <Text className='my-font' type={"primary"}>
                {intl.formatMessage({ id: "ArticlesOfAssociation" })}
              </Text>
              <br />
              <Upload
                action={`${apiUrl}/company/document?name=articlesOfAssociation`}
                headers={{ authorization: "Bearer " + user.token }}
                name='file'
                fileList={ArticlesOfAssociation}
                listType='file'
                onChange={(info) =>
                  handleChangeImages(info, "ArticlesOfAssociation")
                }
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                multiple={false}
                maxCount={0}
                accept='image/jpg,image/jpeg,image/png,application/pdf'
                disabled={loading}
              >
                <Button
                  type='primary'
                  className='border-8 mt-2'
                  disabled={loading}
                  icon={<UploadOutlined />}
                >
                  {intl.formatMessage({ id: "clickToUpload" })}
                </Button>
              </Upload>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Text className='my-font' type={"primary"}>
                {intl.formatMessage({ id: "ids" })}
              </Text>
              <br />
              <Upload
                action={`${apiUrl}/company/document?name=ids`}
                headers={{ authorization: "Bearer " + user.token }}
                name='file'
                fileList={ids}
                listType='file'
                onChange={(info) => handleChangeImages(info, "ids")}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                multiple={false}
                maxCount={0}
                accept='image/jpg,image/jpeg,image/png,application/pdf'
                disabled={loading}
              >
                <Button
                  type='primary'
                  disabled={loading}
                  className='border-8 mt-2'
                  icon={<UploadOutlined />}
                >
                  {intl.formatMessage({ id: "clickToUpload" })}
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }} className='mt-5'>
            <Col md={12} sm={24} xs={24}>
              <Text className='my-font' type={"primary"}>
                {intl.formatMessage({ id: "BankAccountLetter" })}
              </Text>
              <br />
              <Upload
                action={`${apiUrl}/company/document?name=bankAccountLetter`}
                headers={{ authorization: "Bearer " + user.token }}
                name='file'
                fileList={BankAccountLetter}
                listType='file'
                onChange={(info) =>
                  handleChangeImages(info, "BankAccountLetter")
                }
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                multiple={false}
                maxCount={0}
                accept='image/jpg,image/jpeg,image/png,application/pdf'
                disabled={loading}
              >
                <Button
                  type='primary'
                  disabled={loading}
                  className='border-8 mt-2'
                  icon={<UploadOutlined />}
                >
                  {intl.formatMessage({ id: "clickToUpload" })}
                </Button>
              </Upload>
            </Col>
            <Col md={12} sm={24} xs={24}></Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default Settings;
