import React, { useEffect, useState } from "react";
import { DatePicker, Form, Space, Spin, Tag, Typography } from "antd";
import "chart.js/auto";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomButton";
import { Table } from "ant-table-extensions";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import COLORS from "../../Style/colors";
import moment from "moment";
import openNotification from "../../Components/Notifications";
import { getAllOrders } from "../../API/fetch";
import { STATUS_TAGS_COLORS } from "../../Types";
import {
  setCountOfOrdersAction,
  setOrdersAction,
} from "../../redux/reducers/companiesReducer";
const { Text, Title, Link } = Typography;
const { RangePicker } = DatePicker;
const Orders = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { rtl } = useSelector((state) => state.settings);
  const { orders, countOfOrders } = useSelector((state) => state.companies);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderSummary, setOrderSummary] = useState({});
  const [driverFilters, setDriverFilters] = useState([]);
  useEffect(() => {
    if (orders?.length === 0) {
      const startDate = moment().subtract(14, "days").format("YYYY-MM-DD");
      const endDate = moment().endOf("day").format("YYYY-MM-DD");
      getOrders(startDate, endDate);
    }
  }, []);

  const getOrders = async (start, end) => {
    try {
      setLoading(true);
      const res = await getAllOrders(start, end);
      dispatch(setCountOfOrdersAction(res?.data?.ordersSentToDrivers));
      if (res?.data?.orders?.length) {
        let allDrivers = [];
        let totalAmount = 0;
        let formatted = res?.data?.orders.map((order) => {
          if (
            order?.driver?.name &&
            !allDrivers.includes(order?.driver?.name)
          ) {
            allDrivers.push(order?.driver?.name);
          }
          let amount = 0;
          if (order?.deliveryFeesFromCompany) {
            amount = order?.deliveryFeesFromCompany;
          }
          totalAmount += amount;
          return {
            key: order?._id,
            driver: order?.driver?.name,
            _id: order?._id,
            status: order?.status,
            pickup: "pickup",
            pickupInfo: {
              lat: order?.pickup_lat,
              lng: order?.pickup_lng,
            },
            dropOff: "dropOff",
            dropOffInfo: {
              lat: order?.dropOff_lat,
              lng: order?.dropOff_lng,
            },
            orderNumber: order?.orderNumber,
            restaurantLogo: order?.restaurant?.logo,
            statusTracking: order?.statusTracking,
            createdAt: order?.createdAt,
            amount,
            orderObject: {
              ...order,
            },
          };
        });
        setOrderSummary({
          totalAmount,
          totalOrders: formatted?.length,
        });
        dispatch(setOrdersAction(formatted));
        setDriverFilters(allDrivers);
      } else {
        dispatch(setOrdersAction([]));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const onFinish = async (values) => {
    const [start, end] = values.date;
    getOrders(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: 50,
      render: (text, r, index) => <Text className='my-font'>{index + 1}</Text>,
    },
    {
      title: intl.formatMessage({ id: "orderNumber" }),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: intl.formatMessage({ id: "driver" }),
      dataIndex: "driver",
      key: "driver",
      filters: driverFilters.map((driver) => {
        return {
          text: driver,
          value: driver,
        };
      }),
      onFilter: (value, record) => record.driver.indexOf(value) === 0,
    },
    {
      title: intl.formatMessage({ id: "amount" }),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <Tag color={STATUS_TAGS_COLORS[text]}>
            {intl.formatMessage({ id: record?.status || "N/A" })}
          </Tag>
        );
      },
      filters: Object.keys(STATUS_TAGS_COLORS).map((key) => {
        return {
          text: intl.formatMessage({ id: key }),
          value: key,
        };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: intl.formatMessage({ id: "pickup" }),
      dataIndex: "pickup",
      key: "pickup",
      render: (text, record) => {
        return (
          <Link
            href={`https://www.google.com/maps/search/?api=1&query=${record?.pickupInfo?.lat},${record?.pickupInfo?.lng}`}
            target='_blank'
          >
            {intl.formatMessage({ id: "view" })}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "dropOff" }),
      dataIndex: "dropOff",
      key: "dropOff",
      render: (text, record) => {
        return (
          <Link
            href={`https://www.google.com/maps/search/?api=1&query=${record?.dropOffInfo?.lat},${record?.dropOffInfo?.lng}`}
            target='_blank'
          >
            {intl.formatMessage({ id: "view" })}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => {
        return moment(text).format("YYYY-MM-DD hh:mm A");
      },
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Title level={4}>{intl.formatMessage({ id: "orders" })}</Title>
        </div>
        <div>
          <div style={{ backgroundColor: COLORS.w }} className='py-4 px-3'>
            <Form
              name='food-form'
              form={form}
              className='form-container'
              layout='inline'
              onFinish={onFinish}
              initialValues={{
                date: [moment().subtract(14, "days"), moment()],
              }}
            >
              <Form.Item
                rules={[{ required: true, message: "Please select Date" }]}
                name='date'
                label={intl.formatMessage({ id: "date" })}
              >
                <RangePicker />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  htmlType='submit'
                  text={intl.formatMessage({ id: "search" })}
                  className={`btnRegister btn-text border-8 px-5 `}
                  loading={loading}
                  disabled={loading}
                  type='primary'
                />
              </Form.Item>
            </Form>
          </div>
          <Title level={5} className='mb-5'>
            {intl.formatMessage({
              id: "countOfOrders",
            })}
            :{" "}
            <Tag
              color='orange'
              style={{
                fontSize: 18,
                padding: "2px 10px",
                borderRadius: 7,
              }}
            >
              {countOfOrders}
            </Tag>
            {intl.formatMessage({
              id: "acceptedOrders",
            })}
            :{" "}
            <Tag
              style={{
                fontSize: 18,
                padding: "2px 10px",
                borderRadius: 7,
              }}
              color='green'
            >
              {orders?.length}
            </Tag>
          </Title>
        </div>
        <div
          style={{
            backgroundColor: "white",
            padding: "10px 5px",
            borderRadius: 7,
          }}
          className='light-shadow'
        >
          <Space
            direction='horizontal'
            className='my-font mx-3'
            style={{ fontSize: 18 }}
          >
            <Text className='my-font'>
              <Tag
                color='gray'
                style={{
                  fontSize: 16,
                  padding: "5px 10px",
                  borderRadius: 7,
                }}
              >
                {intl.formatMessage({ id: "total" })} :{" "}
                {orderSummary?.totalAmount} SAR
              </Tag>

              <Tag
                color='gray'
                style={{
                  fontSize: 16,
                  padding: "5px 10px",
                  borderRadius: 7,
                }}
              >
                {" "}
                {intl.formatMessage({ id: "orders" })} :{" "}
                {orderSummary?.totalOrders}
              </Tag>
            </Text>
          </Space>

          <Table
            searchable
            fuzzySearch
            exportable
            exportableProps={{
              disabled: loading || orders?.length === 0,
              btnProps: {
                icon: <FileExcelOutlined />,
                type: "primary",
                style: { borderRadius: 7, position: "relative", zIndex: -20 },
                id: "exportBTN",
              },
              children: (
                <Text
                  className='my-font'
                  style={{
                    color: orders?.length === 0 ? "#dddddd" : COLORS.white,
                  }}
                >
                  {intl.formatMessage({ id: "export" })}
                </Text>
              ),
            }}
            searchableProps={{
              debounce: true,
              fuzzySearch: true,
              inputProps: {
                placeholder: "Search this table",
                prefix: <SearchOutlined />,
                disabled: loading || orders?.length === 0,
              },
            }}
            pagination={{
              position: ["bottomLeft"],
              pageSizeOptions: [10, 25, 50, 100, 200, 500],
            }}
            columns={columns}
            loading={loading}
            dataSource={orders}
            scroll={{ x: 1000 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default Orders;
