let apiUrl;
const expressPort = 3008;

// export const streamUrl = "https://stream.iwaiter.net/v1/stream";
const apiUrls = {
  production: "https://api.driver.iwaiter.club/api/v1",
  development: `http://localhost:${expressPort}/api/v1`,
};

if (
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("192")
) {
  apiUrl = apiUrls.development;
} else {
  apiUrl = apiUrls.production;
}

export default apiUrl;
