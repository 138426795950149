import { createSlice } from "@reduxjs/toolkit";
import { arrAdd, arrUpdateByKeyValue } from "../../../helpers/array";

const initialState = {
  companies: [],
  company: {},
  drivers: [],
  orders: [],
  countOfOrders: 0,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    addCompany: (state, action) => {
      state.companies = arrAdd({
        arr: state.companies,
        newElement: action.payload,
      });
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setDrivers: (state, action) => {
      state.drivers = action.payload;
    },
    addDriver: (state, action) => {
      state.drivers = arrAdd({
        arr: state.drivers,
        newElement: action.payload,
      });
    },
    editDriver: (state, action) => {
      state.drivers = arrUpdateByKeyValue({
        arr: state.drivers,
        key: "_id",
        value: action.payload._id,
        newElement: action.payload,
      });
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setCountOfOrders: (state, action) => {
      state.countOfOrders = action.payload;
    }
  },
});

export const { 
  setCompanies: setCompaniesAction,
  addCompany: addCompanyAction,
  setCompany: setCompanyAction,
  setDrivers: setDriversAction,
  editDriver: editDriverAction,
  addDriver: addDriverAction,
  setOrders: setOrdersAction,
  setCountOfOrders: setCountOfOrdersAction,
 } = companiesSlice.actions;

export default companiesSlice.reducer;
