import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { message } from "antd";
import Register from "./Views/Auth/index";
import Sidebar from "./Components/Sidebar";
import PrivateRoute from "./Components/PrivateRoute";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Home from "./Views/Home";
import Admin from "./Views/Admin";
import OneCompany from "./Views/OneCompany";
import Orders from "./Views/Orders";
import Drivers from "./Views/Drivers";
import Settings from "./Views/Settings";
import PaymentRecords from "./Views/PaymentRecords";
import Payments from "./Views/Admin/Payments";

const AllRoutes = () => {
  const intl = useIntl();
  const { isOffline } = useSelector((state) => state.settings);

  useEffect(() => {
    if (isOffline) {
      message.error(intl.formatMessage({ id: "pleaseCheckInternet" }), 0);
    } else {
      message.destroy();
    }
  }, [isOffline]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Sidebar component={<Home />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/admin'
        element={
          <PrivateRoute>
            <Sidebar component={<Admin />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/admin/company/:id'
        element={
          <PrivateRoute>
            <Sidebar component={<OneCompany />} />
          </PrivateRoute>
        }
      />

      <Route
        path='/orders'
        element={
          <PrivateRoute>
            <Sidebar component={<Orders />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/drivers'
        element={
          <PrivateRoute>
            <Sidebar component={<Drivers />} />
          </PrivateRoute>
        }
      />
       <Route
        path='/paymentRecords'
        element={
          <PrivateRoute>
            <Sidebar component={<PaymentRecords />} />
          </PrivateRoute>
        }
      />
       <Route
        path='/payments'
        element={
          <PrivateRoute>
            <Sidebar component={<Payments />} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings'
        element={
          <PrivateRoute>
            <Sidebar component={<Settings />} />
          </PrivateRoute>
        }
      />

      {/* <Route path='/edit-info' element={<ThirdGradInfo />} /> */}
      <Route path='*' element={<Register />} />
    </Routes>
  );
};

export default AllRoutes;
