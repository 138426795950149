import React, { useState } from "react";
import { Row, Typography } from "antd";
import "chart.js/auto";
const { Text, Title } = Typography;


const Home = () => {
  const [data, setData] = useState([]);
  const [late, setLate] = useState([]);
 

  return (
    <Row
      style={{
        minHeight: "calc(100vh - 100px)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
      }}
    >
     <h1>Home</h1>
      
    </Row>
  );
};

export default Home;
