import axios from "axios";
import { api } from "./index";

export const signin = (data) => {
  return api.post(`/sign-in`, data);
};

export const postLogOut = () => {
  return api({
    method: "DELETE",
    url: `/sign-out`,
  });
};

export const getAllCompanies = () => {
  return api({
    method: "GET",
    url: `/companies`,
  });
}

export const createNewCompany = (data) => {
  return api({
    method: "POST",
    url: `/companies`,
    data,
  });
} 

export const getCompany = (id) => {
  return api({
    method: "GET",
    url: `/companies/${id}`,
  });
}

export const deleteCompany = (id) => {
  return api({
    method: "DELETE",
    url: `/companies/${id}`,
  });
}

export const getAllDrivers = () => {
  return api({
    method: "GET",
    url: `/drivers`,
  });
}

export const addDriver = (data, type) => {
  return api({
    method: "POST",
    url: `/drivers?type=${type}`,
    data,
  });
}

export const editDriver = (id, data) => {
  return api({
    method: "PATCH",
    url: `/drivers/${id}`,
    data,
  });
}

export const createToken = () => {
  return api({
    method: "GET",
    url: `/generate-token`,
  });
}

export const editCompany = ( data) => {
  return api({
    method: "PATCH",
    url: `/company`,
    data,
  });
}

export const sendOrderTest = (lat, lng) => {
  return api({
    method: "POST",
    url: `/order-test`,
    data: {
      lat,
      lng
    }
  });
}

export const getAllOrders = (startDate, endDate) => {
  return api({
    method: "GET",
    url: `/orders?startDate=${startDate}&endDate=${endDate}`,
  });
}
  
export const getCompanyPaymentBalance = () => {
  return api({
    method: "GET",
    url: `/company-payment-balance`,
  });
}

export const getPayments = (query) => {
  return api({
    method: "GET",
    url: `/payments-admin${query}`,
  });
}

export const createTransaction = (data) => {
  return api({
    method: "POST",
    url: `/transactions`,
    data,
  });
}