import React from "react";
import { Menu, Typography } from "antd";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { logOutAction } from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  IoHomeOutline,
  IoLogOut,
  IoPeopleOutline,
  IoLanguageOutline,
  IoCardOutline,
} from "react-icons/io5";
import { InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { MdOutlineRotate90DegreesCcw } from "react-icons/md";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";

const { Text } = Typography;
const SuperUserSide = ({ toggleSideBar }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  return (
    <Menu theme='light' mode='inline' defaultSelectedKeys={["1"]}>
      <Menu.Item
        key='home'
        icon={<IoHomeOutline size={20} />}
        onClick={() => {
          // toggleSideBar();
        }}
      >
        <Link to='/'>{intl.formatMessage({ id: "home" })}</Link>
      </Menu.Item>

      {user?.role === "admin" ? (
        <Menu.Item
          onClick={() => {
            toggleSideBar();
          }}
          key='admin'
          icon={<InfoCircleOutlined size={20} />}
        >
          <Link to='/admin'>{intl.formatMessage({ id: "admin" })}</Link>
        </Menu.Item>
      ) : null}
      {user?.role === "admin" ? null : (
        <Menu.Item
          onClick={() => {
            toggleSideBar();
          }}
          key='settings'
          icon={<SettingOutlined size={20} />}
        >
          <Link to='/settings'>{intl.formatMessage({ id: "settings" })}</Link>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          toggleSideBar();
        }}
        key='drivers'
        icon={<IoPeopleOutline size={20} />}
      >
        <Link to='/drivers'>{intl.formatMessage({ id: "drivers" })}</Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          toggleSideBar();
        }}
        key='orders'
        icon={<MdOutlineRotate90DegreesCcw size={20} />}
      >
        <Link to='/orders'>{intl.formatMessage({ id: "orders" })}</Link>
      </Menu.Item>
      {user?.role === "admin" ? (
        <Menu.Item
          onClick={() => {
            toggleSideBar();
          }}
          key='payments'
          icon={<IoCardOutline size={20} />}
        >
          <Link to='/payments'>{intl.formatMessage({ id: "payments" })}</Link>
        </Menu.Item>
      ) : (
        <Menu.Item
          onClick={() => {
            toggleSideBar();
          }}
          key='payment-records'
          icon={<IoCardOutline size={20} />}
        >
          <Link to='/paymentRecords'>
            {intl.formatMessage({ id: "paymentRecords" })}
          </Link>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          dispatch(changeLanguageAction());
          toggleSideBar();
        }}
        key='language'
        icon={<IoLanguageOutline size={20} />}
      >
        <Text className='my-font'>
          {intl.formatMessage({ id: "oppLanguage" })}
        </Text>
      </Menu.Item>

      <Menu.Item
        key='signOut'
        onClick={() => {
          dispatch(logOutAction());
          // toggleSideBar();
        }}
        icon={<IoLogOut size={20} />}
      >
        <Text className='my-font'>{intl.formatMessage({ id: "signout" })}</Text>
      </Menu.Item>
    </Menu>
  );
};

export default SuperUserSide;
