import React, { useState, useEffect } from "react";
import { Space, Spin, Tag, Typography } from "antd";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";
import { getCompanyPaymentBalance } from "../../API/fetch";
import { useSelector } from "react-redux";
import { DEFAULT_WALLET_NAME } from "../../Types";
import moment from "moment";
import { Table } from "ant-table-extensions";
import { FileExcelOutlined } from "@ant-design/icons";
import { customerExport } from "../../helpers";

const { Title, Text, Link } = Typography;
const PaymentRecords = () => {
  const intl = useIntl();
  const { company } = useSelector((state) => state.companies);
  const [initialLoading, setInitialLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [amountToTransfer, setAmountToTransfer] = useState(0);

  useEffect(() => {
    getPaymentBalance();
  }, []);

  const getPaymentBalance = async () => {
    try {
      const res = await getCompanyPaymentBalance();
      console.log(res.data);
      setAmountToTransfer(res.data.amountToTransfer);
      let formatted = formatTransaction(res.data.transactions);
      setTransactions(formatted);

      setInitialLoading(false);
    } catch (error) {
      console.log(error);
      setInitialLoading(false);
    }
  };

  const formatTransaction = (transactions) => {
    let formatted = [];
    if (transactions?.length) {
      transactions.forEach((transaction, i) => {
        let amountPaidForServices = 0;
        if (transaction?.amountPaidForServices > 0) {
          amountPaidForServices =
            transaction?.amountPaidForServices?.toFixed(2);
        }
        let total = transaction?.amount - amountPaidForServices;
        let forOrdersBetween = `${moment(transaction?.startDate).format(
          "YYYY/MM/DD hh:mm"
        )} - ${moment(transaction?.endDate).format("YYYY/MM/DD hh:mm")}`;
        formatted.push({
          key: transaction?._id,
          number: i + 1,
          _id: transaction?._id,
          amount: transaction?.amount?.toFixed(2),
          total: total?.toFixed(2),
          transactionNumber: transaction?.transactionNumber,
          status: transaction?.status,
          invoiceUrl: transaction?.invoiceUrl,
          transferTime: moment(transaction?.createdAt).format("DD/MM/YYYY"),
          forOrdersBetween,
          amountPaidForServices,
          transactionObject: { ...transaction },
        });
      });
    }
    return formatted;
  };

  const getNextTransfer = () => {
    // get next  Thursday date from now;
    const now = moment();
    // get this Thursday;
    const Thursday = moment(moment().day("Thursday"));
    // if now is after Thursday, get next Thursday;
    if (now.isAfter(Thursday)) {
      return moment(Thursday).add(1, "weeks").format("DD/MM/YYYY");
    }
    return moment(Thursday).format("DD/MM/YYYY");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: intl.formatMessage({ id: "transferNumber" }),
      dataIndex: "transactionNumber",
      key: "transactionNumber",
    },
    {
      title: intl.formatMessage({ id: "total" }),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return <Tag color={"green"}>{status}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "transferTime" }),
      dataIndex: "transferTime",
      key: "transferTime",
      sorter: (a, b) =>
        new Date(a.transactionObject?.createdAt) -
        new Date(b.transactionObject?.createdAt),
      showSorterTooltip: false,
    },
    {
      title: intl.formatMessage({ id: "forOrdersBetween" }),
      dataIndex: "forOrdersBetween",
      key: "forOrdersBetween",
    },
    {
      title: intl.formatMessage({ id: "invoice" }),
      dataIndex: "invoiceUrl",
      key: "invoiceUrl",
      render: (invoiceUrl) => {
        return (
          <a
            style={{
              color: invoiceUrl ? "#1890ff" : "gray",
              textDecoration: invoiceUrl ? "underline" : "none",
              cursor: invoiceUrl ? "pointer" : "not-allowed",
            }}
            href={invoiceUrl}
            target='_blank'
          >
            {intl.formatMessage({ id: "invoice" })}
          </a>
        );
      },
    },
  ];

  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Title level={3}>{intl.formatMessage({ id: "allPayments" })}</Title>
      </div>
      <Spin spinning={initialLoading}>
        <Space
          direction='vertical'
          justify='center'
          align='center'
          style={{ width: "100%" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginBottom: 10,
            }}
          >
            <Space direction='horizontal' justify='center' align='start'>
              <Title level={5} className='my-font'>
                {intl.formatMessage({ id: "yourBalance" })}
              </Title>
              <Tag color={"green"}>{amountToTransfer?.toFixed(2)}</Tag>
            </Space>
            <Space direction='horizontal' justify='center' align='start'>
              <Title level={5} className='my-font'>
                {intl.formatMessage({ id: "nextDateForTransaction" })}
              </Title>
              <Text keyboard>{getNextTransfer()}</Text>
            </Space>
          </div>
        </Space>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 40,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className='light-shadow'
        >
          <Table
            exportable
            exportableProps={{
              showColumnPicker: true,
              btnProps: {
                icon: <FileExcelOutlined />,
                type: "primary",
                style: { borderRadius: 7 },
                onClick: () =>
                  customerExport({
                    data: transactions,
                    columns,
                    fileName: "Transactions Report",
                    loadingSetter: setInitialLoading,
                  }),
              },
              children: (
                <Text
                  className='my-font'
                  style={{
                    color: COLORS.white,
                  }}
                >
                  {intl.formatMessage({ id: "export" })}
                </Text>
              ),
            }}
            pagination={{
              hideOnSinglePage: true,
            }}
            dataSource={transactions}
            scroll={{ x: 500 }}
            columns={columns}
            style={{ borderRadius: 10 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default PaymentRecords;
