import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Typography, Divider, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
import moment from "moment";
import cities from "../../Types/cities";
import { createNewCompany } from "../../API/fetch";
import { addCompanyAction } from "../../redux/reducers/companiesReducer";
const { Option } = Select;
const { Title, Text } = Typography;

const AddCompanyModal = ({ show, hide }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const addTransaction = async (values) => {
    try {
      setLoading(true);
      const data = {
        company: {
          name: values?.companyName,
          nameAr: values?.companyNameAr,
          phone: `966${values?.phone}`,
          email: values?.email,
          city: values?.city,
          deliveryFees: values?.deliveryFees,
        },
        manger: {
          name: values?.mangerName,
          phone: `966${values?.phone}`,
          password: values?.password,
          email: values?.email,
        },
      };

      const res = await createNewCompany(data);
      if (res?.data?.company) {
        dispatch(addCompanyAction(res?.data?.company));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
        setLoading(false);
        hide();
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    } catch (error) {
      console.log(error?.response?.data);
      if(error?.response?.data?.error?.code === 11000) {
        if(error?.response?.data?.error?.keyValue?.phone) {
          openNotification({
            title: intl.formatMessage({ id: "error" }),
            description: "phone number already exist",
            type: "error",
            rtl,
          });
          setLoading(false);
          return
        }
      }
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };
  return (
    <Modal
      title={
        <Title level={4} className='my-font'>
          {intl.formatMessage({ id: "addCompany" })}
        </Title>
      }
      centered
      visible={show}
      destroyOnClose={true}
      mask={true}
      maskClosable={true}
      onCancel={() => {
        hide();
      }}
      footer={null}
    >
      <Form
        name='addCompany'
        form={form}
        preserve={false}
        className='form-container'
        layout='vertical'
        onFinish={addTransaction}
        initialValues={{
          companyName: "",
          companyNameAr: "",
          mangerName: "",
          phone: "",
          email: "",
          city: "",
          deliveryFees: "",
          password: "",
        }}
      >
        {() => {
          return (
            <>
              <Form.Item
                label={`${intl.formatMessage({ id: "companyName" })} - ${intl.formatMessage({id: 'nameEn'})}`}
                className='mb-1'
                name='companyName'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input className={"input"} />
              </Form.Item>
              <Form.Item
                label={`${intl.formatMessage({ id: "companyName" })} - ${intl.formatMessage({id: 'nameAr'})}`}
                className='mb-1'
                name='companyNameAr'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input className={"input"} />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "mangerName" })}
                className='mb-1'
                name='mangerName'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input className={"input"} />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "phone" })}
                className='mb-1'
                name='phone'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                  // format number 0-9, with 9 digits, and start with 5;
                  {
                    pattern: new RegExp(/^[5][0-9]{8}$/),
                    message: "pleas add valid phone number",
                  },
                ]}
              >
                <Input
                  addonBefore={rtl ? null : "+966"}
                  addonAfter={!rtl ? null : "966+"}
                  className={"input"}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "password" })}
                className='mb-1'
                name='password'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Input.Password className={"input"} />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "email" })}
                className='mb-1'
                name='email'
                rules={[
                  {
                    type: "email",
                    message: "pleas add valid email",
                  },
                ]}
              >
                <Input className={"input"} />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "cityName" })}
                className='mb-1'
                name='city'
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "errorname" }),
                  },
                ]}
              >
                <Select className='my-font' showSearch>
                  {cities.map((info) => {
                    return (
                      <Option
                        key={info?.city_id}
                        value={`${info?.name_en} - ${info?.name_ar}`}
                      >
                        {info.name_en} - {info.name_ar}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "deliveryFees" })}
                className='mb-1'
                name='deliveryFees'
                rules={[
                  {
                    // format number 0-9
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "pleas add numbers",
                  },
                ]}
              >
                <Input className={"input"} />
              </Form.Item>

              <Divider />
              <Space direction='horizontal'>
                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "save" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    type='primary'
                  />
                </Form.Item>

                <Form.Item>
                  <CustomButton
                    type='text'
                    text={intl.formatMessage({ id: "cancel" })}
                    disabled={loading}
                    onClick={() => {
                      hide();
                    }}
                  />
                </Form.Item>
              </Space>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default AddCompanyModal;
